import { useAuthState } from 'react-firebase-hooks/auth'
import { RefObject, useEffect } from 'react'
import { auth } from './firebase'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUserData() {
  return useAuthState(auth)
}
export function useOnClickOutside<T extends HTMLElement>(
  ref: RefObject<T | undefined>,
  handler: () => void
): void {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (ref?.current?.contains(event.target as Document)) {
          return
        }
        handler()
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}
