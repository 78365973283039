import { useCookies } from 'react-cookie'
import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames'
import { CookieIcon } from './icon'
import { Button } from '@/components/elements/Button'
import { Link } from '@/components/elements/Link'
import { CheckboxGroup } from '@/components/elements/Checkbox/group'
import { ExternalPolicy, TechnicalPolicy, TrackingPolicy } from './policies'
import { CheckboxCookie } from '@/components/elements/Checkbox/cookies'

export type CookiePreferences = {
  acceptTechnical: boolean
  acceptTracking: boolean
  acceptExternal: boolean
}

const DEFAULT_COOKIE_STATUS: CookiePreferences = {
  acceptTechnical: true,
  acceptTracking: false,
  acceptExternal: false,
}

const DEFAULT_COOKIE_ACCEPT_WITHOUT_CONFIG: CookiePreferences = {
  acceptTechnical: true,
  acceptTracking: true,
  acceptExternal: true,
}

export const CookieBanner: React.FC<{
  onManageCookies?: (cookiePreferences: CookiePreferences) => void
}> = ({ onManageCookies = () => null }) => {
  const [cookies, setCookie] = useCookies(['cookie-consent-cinexic'])
  const [showConfig, setShowConfig] = useState(false)
  const [preferencesOpened, setPreferencesOpened] = useState(false)
  const [cookiesValue, setCookiesValue] = useState(
    cookies['cookie-consent-cinexic'] || DEFAULT_COOKIE_STATUS
  )

  function setConsent() {
    if (preferencesOpened) {
      setCookie('cookie-consent-cinexic', cookiesValue, { path: '/' })
    } else {
      setCookie(
        'cookie-consent-cinexic',
        DEFAULT_COOKIE_ACCEPT_WITHOUT_CONFIG,
        {
          path: '/',
        }
      )
    }
  }

  function setShowPreferences(value: boolean) {
    setShowConfig(value)
    setPreferencesOpened(true)
  }

  function handleCookies(name: string, value: boolean) {
    const values = cookiesValue
    values[name] = value
    setCookiesValue(values)
  }

  useEffect(() => {
    const cookieData = cookies['cookie-consent-cinexic']

    if (cookieData) {
      if (cookieData === 'TRUE') {
        setCookie(
          'cookie-consent-cinexic',
          DEFAULT_COOKIE_ACCEPT_WITHOUT_CONFIG,
          {
            path: '/',
          }
        )

        onManageCookies(DEFAULT_COOKIE_ACCEPT_WITHOUT_CONFIG)
      } else {
        onManageCookies(cookieData)
      }
    }
  }, [cookies, onManageCookies, setCookie])

  return (
    <AnimatePresence>
      {!cookies['cookie-consent-cinexic'] && (
        <motion.div
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '100%' }}
          className={classNames(
            showConfig ? 'overflow-y-auto overscroll-none' : '',
            'cookie-banner fixed z-30 bottom-0 left-0 right-0 flex flex-col items-start p-6 text-sm bg-navy-light-50 space-y-4 lg:space-y-0'
          )}
        >
          {showConfig && (
            <div className="p-6 text-sm space-y-4 lg:ml-22 lg:space-y-0">
              <div className="flex flex-col items-start">
                <CheckboxGroup name="acceptTechnical">
                  <CheckboxCookie
                    name="acceptTechnical"
                    id="acceptTechnical"
                    label="Tècniques"
                    description={<TechnicalPolicy />}
                    checked
                    disabled
                    readOnly
                  />
                </CheckboxGroup>
                <CheckboxGroup name="acceptTracking">
                  <CheckboxCookie
                    name="acceptTracking"
                    id="acceptTracking"
                    label="Publicitat Comportamental"
                    description={<TrackingPolicy />}
                    defaultChecked={cookiesValue.acceptTracking}
                    onClick={({ target }) => {
                      handleCookies(target.name, target.checked)
                    }}
                  />
                </CheckboxGroup>
                <CheckboxGroup name="acceptExternal">
                  <CheckboxCookie
                    name="acceptExternal"
                    id="acceptExternal"
                    label="Comunicació"
                    description={<ExternalPolicy />}
                    defaultChecked={cookiesValue.acceptExternal}
                    onClick={({ target }) => {
                      handleCookies(target.name, target.checked)
                    }}
                  />
                </CheckboxGroup>
              </div>
            </div>
          )}
          <div className="flex flex-col items-start p-6 text-sm bg-navy-light-50 space-y-4 lg:flex-row lg:space-y-0">
            <div className="flex-shrink-0 w-12">
              <CookieIcon width="100%" />
            </div>
            <div className="lg:px-10">
              <p>
                Aquest web utilitza cookies pròpies i de tercers amb la
                finalitat de permetre la navegació de l&apos;usuari, el
                seguiment i anàlisi del seu comportament i la confecció de
                publicitat a mida. Pots acceptar les cookies amb el botó
                &quot;Acceptar&quot; o configurar-les o refusar-les a través del
                panell de configuració, en el botó &quot;Preferències&quot;.{' '}
                <Link href="/politica-de-cookies">Més informació.</Link>
              </p>
            </div>
            <div className="flex space-x-2">
              <Button
                onClick={() => setConsent()}
                className="border-2"
                label="Accepta"
                size="small"
              />
              <Button
                onClick={() => setShowPreferences(!showConfig)}
                label="Preferències"
                size="small"
                variant="outlined"
                className="py-4"
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
