import { ReactElement } from 'react'

export const ErrorMessageWrapper = (props): ReactElement => {
  return (
    <div className="mt-4 p-3 text-black bg-attention rounded-md">
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </div>
  )
}
