import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth } from '../lib/firebase'

const httpLink = createHttpLink({
  uri: '/api/graphql',
})

const authLink = setContext((_, { headers }) => {
  if (!auth.currentUser) {
    const url = new URL(window.location.href)
    const cinemaToken = url.searchParams.get('token')
    return {
      headers: {
        ...headers,
        'cinema-token': cinemaToken,
      },
    }
  }
  return auth.currentUser.getIdToken().then((token) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
