import classNames from 'classnames'
import { ErrorMessage } from '@hookform/error-message'
import { AlertCircleOutline } from 'react-ionicons'
import { ErrorMessageWrapper } from '@/components/elements/ErrorMessageWrapper'
import { IonIcon } from '@/src/icons'

export interface CheckboxGroupProps {
  className?: string
  errors?: any
  title?: string
  subTitle?: string
  name: string
  [key: string]: any
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { errors, className = '', title, subTitle, name, children } = props
  return (
    <div className={classNames('flex items-start mt-4', className)}>
      {errors && errors[name] && (
        <IonIcon
          className="flex-shrink-0 -ml-7 mr-2 w-5 h-5 text-attention"
          icon={AlertCircleOutline}
        />
      )}
      <div className="flex-grow">
        <span className="block font-semibold">{title}</span>
        {subTitle && (
          <span className="block text-navy-100 text-sm">{subTitle}</span>
        )}
        <div className="flex flex-col">{children}</div>
        {errors && (
          <ErrorMessage errors={errors} name={name} as={ErrorMessageWrapper} />
        )}
      </div>
    </div>
  )
}
