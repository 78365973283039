export const TechnicalPolicy = (): JSX.Element => {
  return (
    <p className="mt-1">
      Aquestes cookies són necessàries per al correcte funcionament del lloc web
    </p>
  )
}

export const TrackingPolicy = (): JSX.Element => {
  return (
    <p className="mt-1">
      Aquestes cookies ens permeten millorar l’experiència de la web analitzant
      com l’usen els nostres usuaris
    </p>
  )
}

export const ExternalPolicy = (): JSX.Element => {
  return (
    <p className="mt-1">
      Aquestes cookies ens permeten millorar l’experiència de la web analitzant
      les relacions dels nostres usuaris amb plataformes externes
    </p>
  )
}
