import { ReactElement, useCallback, useEffect, useState } from 'react'
import '../styles/globals.css'
import { AppProps } from 'next/app'
import { RouteGuard } from 'components/RouteGuard'
import { ApolloProvider } from '@apollo/client'
import TagManager from 'react-gtm-module'
import { useRouter } from 'next/router'
import {
  CookieBanner,
  CookiePreferences,
} from '@/components/patterns/CookieBanner'

import { UserContext } from '../lib/context'
import { useUserData } from '../lib/hooks'
import client from '../apollo/apollo-client'

function MyApp({ Component, pageProps }: AppProps): ReactElement {
  const userData = useUserData()
  const router = useRouter()

  const trackView = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        url: router.asPath,
      },
    })
  }, [router.asPath])

  useEffect(() => trackView(), [trackView, router.asPath])

  const initializeCookies = ({ acceptTracking }: CookiePreferences) => {
    if (acceptTracking) {
      initializeGtm()
    }
  }

  const [gtmInitialized, setGtmInitialized] = useState(false)

  const initializeGtm = useCallback(() => {
    if (!gtmInitialized) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
    }

    setGtmInitialized(true)
  }, [gtmInitialized])
  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={userData}>
        <RouteGuard>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </RouteGuard>
        <CookieBanner onManageCookies={(values) => initializeCookies(values)} />
      </UserContext.Provider>
    </ApolloProvider>
  )
}

export default MyApp
