import getConfig from 'next/config'
import firebase from 'firebase/app'
import 'firebase/auth'

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} }

const firebaseConfig = {
  apiKey: 'AIzaSyCRdvVKyWzMtFZ8nh97OkqdpdS-PY94dls',
  authDomain: 'omnium-cinexic.firebaseapp.com',
  projectId: 'omnium-cinexic',
  storageBucket: 'omnium-cinexic.appspot.com',
  messagingSenderId: '338270764701',
  appId: '1:338270764701:web:2bdee89c0fbe0b64245e74',
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
  if (publicRuntimeConfig.useEmulator) {
    firebase.auth().useEmulator('http://localhost:9099')
  }
}

export const auth = firebase.auth()
